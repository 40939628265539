export const appV2EventPrefix = "HCP Mobile App (V2):";

export const APP_V2_USER_EVENTS: Record<string, string> = {
  // Auth
  SUBMIT_LOGIN_FORM_WITH_PHONE_NUMBER: `${appV2EventPrefix} Submit Login Form With Phone Number`,
  SUBMIT_LOGIN_FORM_WITH_EMAIL: `${appV2EventPrefix} Submit Login Form With Email`,
  SWITCH_LOGIN_METHOD: `${appV2EventPrefix} Switch Login Method`,
  SUBMIT_SIGNUP_FORM: `${appV2EventPrefix} Submit Signup Form`,
  SWITCH_BETWEEN_LOGIN_SIGNUP: `${appV2EventPrefix} Switch Between Login and Signup`,
  SUBMIT_AUTH_OTP_FORM: `${appV2EventPrefix} Submit OTP form`,
  SUBMIT_AUTH_OTP_FORM_SUCCESS: `${appV2EventPrefix} Submit OTP form success`,
  SUBMIT_AUTH_OTP_FORM_ERROR: `${appV2EventPrefix} Submit OTP form error`,
  RESEND_OTP: `${appV2EventPrefix} Resend OTP`,
  CLOSE_VERIFY_OTP_DIALOG: `${appV2EventPrefix} Close OTP form`,
  CLOSE_LOGIN_CONFIRMATION_DIALOG: `${appV2EventPrefix} Close Login Confirmation Dialog`,
  SELECT_PHONE_NUMBER_EXISTS_LOGIN: `${appV2EventPrefix} Phone Number Exists Login`,
  CLOSE_PHONE_NUMBER_EXISTS_DIALOG: `${appV2EventPrefix} Close Phone Number Exists Dialog`,
  SELECT_SIGN_UP_INSTEAD: `${appV2EventPrefix} Sign Up Instead`,
  CLOSE_SIGN_UP_INSTEAD_DIALOG: `${appV2EventPrefix} Close Sign Up Instead Dialog`,
  VIEWED_WHATS_NEXT: `${appV2EventPrefix} Viewed What's Next information`,

  // Open Shifts
  OPEN_SHIFTS_CALENDAR_VIEWED: `${appV2EventPrefix} Open Shift Calendar Viewed`,
  OPEN_SHIFTS_CALENDAR_DATE_SELECTED: `${appV2EventPrefix} Open Shift Calendar Date Selected`,
  OPEN_SHIFTS_CALENDAR_SELECT_PREVIOUS: `${appV2EventPrefix} Open Shift Calendar Select Previous`,
  OPEN_SHIFTS_CALENDAR_SELECT_NEXT: `${appV2EventPrefix} Open Shift Calendar Select Next`,
  OPEN_SHIFTS_CALENDAR_OPEN_FILTER_LICENSE: `${appV2EventPrefix} Open Shift Calendar Open Filter License`,
  OPEN_SHIFTS_CALENDAR_OPEN_FILTER_DISTANCE: `${appV2EventPrefix} Open Shift Calendar Open Filter Distance`,
  OPEN_SHIFTS_CALENDAR_SUBMIT_FILTER_DISTANCE_DIALOG: `${appV2EventPrefix} Open Shift Calendar Submit Filter Distance`,
  OPEN_SHIFTS_CALENDAR_SUBMIT_FILTER_LICENSE_DIALOG: `${appV2EventPrefix} Open Shift Calendar Submit Filter License`,
  OPEN_SHIFT_COLLEAGUES_VIEWED: `${appV2EventPrefix} Open shift colleagues viewed`,
  OPEN_SHIFTS_DAY_VIEW_VIEWED: `${appV2EventPrefix} Open Shifts Day View V2 Viewed`,
  OPEN_SHIFTS_DAY_VIEW_SHIFT_PERIOD_SELECTED: `${appV2EventPrefix} Open Shift Day View V2 Shift Period Selected`,
  VIEWED_NEXT_OPEN_SHIFT_PAGE: `${appV2EventPrefix} Viewed Next Open Shifts Page`,

  // Shift Actions
  INSTANT_BOOKED_SHIFT: `${appV2EventPrefix} Instant Booked Shift`,
  MARKED_INTEREST_SHIFT: `${appV2EventPrefix} Marked Interest Shift`,

  // Worker Availability
  AVAILABILITY_CALENDAR_VIEWED: `${appV2EventPrefix} Availability Calendar Viewed`,

  // Reviews
  WORKPLACE_REVIEWS_RATING_SUBMITTED: `${appV2EventPrefix} Workplace Reviews Rating Submitted`,
  WORKPLACE_REVIEWS_RATING_UPDATED: `${appV2EventPrefix} Workplace Reviews Rating Updated`,
  WORKPLACE_REVIEWS_REVIEW_SUBMITTED: `${appV2EventPrefix} Workplace Reviews Review Submitted`,
  WORKPLACE_REVIEWS_SCREEN_OPENED: `${appV2EventPrefix} Workplace Reviews Screen Opened`,
  WORKPLACE_REVIEWS_SCREEN_CLOSED: `${appV2EventPrefix} Workplace Reviews Screen Closed`,
  WORKPLACE_REVIEWS_OPENED_FROM_FEEDBACK_MODAL: `${appV2EventPrefix} Workplace Reviews Screen Opened From Feedback Modal`,

  // Refer and earn intermediate screen
  REFER_AND_EARN: `${appV2EventPrefix} Refer and Earn`,

  // Workplace referral events
  WORKPLACE_REFERRAL: `${appV2EventPrefix} Workplace Referral`,
  WORKPLACE_REFERRAL_STATS: `${appV2EventPrefix} Workplace Referral Stats`,

  // Support
  OPENED_SUPPORT_ARTICLE: `${appV2EventPrefix} Opened Support Article`,

  // Worker to Worker Referrals events
  VIEWED_WORKER_TO_WORKER_REFERRAL_SCREEN: `${appV2EventPrefix} Viewed worker to worker referral screen`,
  SHARED_CODE_FROM_WORKER_TO_WORKER_REFERRAL_SCREEN: `${appV2EventPrefix} Shared code from worker to worker referral screen`,
  VISITED_ENTER_REFERRAL_CODE_FROM_WORKER_TO_WORKER_REFERRAL_SCREEN: `${appV2EventPrefix} Visited enter referral code screen from worker to worker referral screen`,
  CLICKED_ON_WORKER_TO_WORKER_REFERRAL_BANNER: `${appV2EventPrefix} Clicked on worker to worker referral banner`,

  // My Shifts
  SUBMITTED_ETA_MODAL: `${appV2EventPrefix} Submitted ETA modal`,
  VIEWED_FACILITY_PROFILE: `${appV2EventPrefix} Facility Profile Viewed`,
  SHIFT_CARD_FACILITY_PROFILE_CLICKED: `${appV2EventPrefix} Shift card facility profile clicked`,
  SHIFT_CARD_CLICKED: `${appV2EventPrefix} MyShifts shift card clicked`,
  UNVERIFIED_SHIFTS_CARD_CLICKED: `${appV2EventPrefix} MyShifts unverified shifts card clicked`,
  SHIFT_CARD_CHAT_BUTTON_CLICKED: `${appV2EventPrefix} Shift card facility chat button clicked`,
  MESSAGES_ICON_CLICKED: `${appV2EventPrefix} Messages icon clicked`,
  PENDING_SENT_HOME_REQUESTS_CARD_CLICKED: `${appV2EventPrefix} MyShifts pending sent home requests card clicked`,

  // Documents
  UPLOAD_DOCUMENT_SUCCESS: `${appV2EventPrefix} Successfully uploaded document`,
  UPLOAD_DOCUMENT_FAILURE: `${appV2EventPrefix} Failed to upload document`,
  DOCUMENT_DETAILS_SCREEN_VIEWED: `${appV2EventPrefix} Document Details Screen Viewed`,
  VIEWED_DOCUMENTS: `${appV2EventPrefix} Documents Viewed`,
  MISSING_DOCUMENTS_ALERT_UPLOAD_BUTTON_CLICKED: `${appV2EventPrefix} Missing documents alert upload button clicked`,
  MISSING_DOCUMENTS_NOTIFY_SUPPORT_BUTTON_CLICKED: `${appV2EventPrefix} Missing documents alert notify support button clicked`,
  DOCUMENTS_PAGE_MORE_INFO_ICON_CLICKED: `${appV2EventPrefix} Documents page more info icon clicked`,

  // Notifications
  NOTIFICATION_CENTER_ICON_VIEWED: `${appV2EventPrefix} Notification center icon viewed`,
  NOTIFICATION_CENTER_VIEWED: `${appV2EventPrefix} Notification center viewed`,

  // Urgent Shifts
  TAPPED_URGENT_SHIFTS_BANNER: `${appV2EventPrefix} Tapped Urgent Shifts Banner`,

  // Hot shifts
  HOT_SHIFT_ICON_TAPPED: `${appV2EventPrefix} Hot Shift Icon Tapped`,

  // Shift bookability
  TAPPED_CONTACT_SUPPORT_FOR_UNDETERMINED_BOOKABILITY_STATUS: `${appV2EventPrefix} Tapped Contact Support For Undetermined Bookability Status`,
  TAPPED_SHIFT_UNAVAILABLE_FOR_BLOCKED_BOOKABILITY_STATUS: `${appV2EventPrefix} Tapped Shift Unavailable For Blocked Bookability Status`,
  TAPPED_BUTTON_FOR_BLOCKED_BOOKABILITY_STATUS: `${appV2EventPrefix} Tapped Button For Blocked Bookability Status`,
  TAPPED_BUTTON_FOR_ALLOWED_BOOKABILITY_STATUS: `${appV2EventPrefix} Tapped Button For Allowed Bookability Status`,

  // Onboarding
  STARTED_STRIPE_SETUP: `${appV2EventPrefix} Started Stripe Setup`,
  VIEWED_STRIPE_SETUP: `${appV2EventPrefix} Viewed Stripe Setup`,

  // Mandatory break policy
  MANDATORY_BREAK_POLICY_VIEWED: `${appV2EventPrefix} Mandatory break policy dialog viewed`,
  MANDATORY_BREAK_POLICY_CANCELLED: `${appV2EventPrefix} Mandatory break policy dialog cancelled`,
  MANDATORY_BREAK_POLICY_ACCEPTED: `${appV2EventPrefix} Mandatory break policy dialog accepted`,

  // WorkplaceQuiz
  VIEWED_QUIZ_PASSED_DIALOG: `${appV2EventPrefix} Viewed quiz passed dialog`,
  VIEWED_QUIZ_FAILED_DIALOG: `${appV2EventPrefix} Viewed quiz failed dialog`,
  TAPPED_BUTTON_FOR_BOOK_SHIFT_ON_QUIZ_PASSED_WITH_SHIFT_DIALOG: `${appV2EventPrefix} Tapped button for book shift on quiz passed with shift dialog`,
  TAPPED_BUTTON_FOR_CANCEL_ON_QUIZ_PASSED_WITH_SHIFT_DIALOG: `${appV2EventPrefix} Tapped button for cancel on quiz passed with shift dialog`,
  TAPPED_BUTTON_FOR_RETAKE_QUIZ_ON_QUIZ_FAILED_DIALOG: `${appV2EventPrefix} Tapped button for retake quiz on quiz failed dialog`,
  TAPPED_BUTTON_FOR_CANCEL_ON_QUIZ_FAILED_DIALOG: `${appV2EventPrefix} Tapped button for cancel on quiz failed dialog`,
  VIEWED_QUIZ_REQUIREMENT_DIALOG: `${appV2EventPrefix} Viewed quiz requirement dialog`,
  TAPPED_BUTTON_FOR_CONTINUE_ON_QUIZ_REQUIREMENT_DIALOG: `${appV2EventPrefix} Tapped button for continue on quiz requirement dialog`,
  VIEWED_WORKPLACE_RULES_PAGE: `${appV2EventPrefix} Viewed workplace rules page`,
  CLOSED_WORKPLACE_RULES_PAGE: `${appV2EventPrefix} Closed workplace rules page`,
  VIEWED_WORKPLACE_QUIZ_PAGE: `${appV2EventPrefix} Viewed workplace quiz page`,
  CLOSED_WORKPLACE_QUIZ_PAGE: `${appV2EventPrefix} Closed workplace quiz page`,

  // Open shifts tab layout
  OPEN_SHIFTS_TAB_LAYOUT: `${appV2EventPrefix} Open shifts tab layout`,

  // Shift blocks
  SHIFT_BLOCKS: `${appV2EventPrefix} Shift Blocks`,

  // Streaks
  VIEWED_STREAK_TRACKER_PAGE: `${appV2EventPrefix} Streak tracker page viewed`,

  VIEWED_OPEN_BLOCKS_FROM_STREAK_TRACKER: `${appV2EventPrefix} Open blocks viewed from streak tracker page`,

  // Negotiation
  VIEWED_NEXT_NEGOTIATION_SHIFT_PAGE: `${appV2EventPrefix} Viewed Next Negotiation Shifts Page`,

  // Account deletion
  REQUEST_ACCOUNT_DELETION_LOGOUT: `${appV2EventPrefix} Log Out After Request Account Deletion`,
  REQUEST_ACCOUNT_DELETION_CONFIRM: `${appV2EventPrefix} Confirm Request Account Deletion`,
  REQUEST_ACCOUNT_DELETION_SEND_OTP: `${appV2EventPrefix} Request OTP For Account Deletion`,
  REQUEST_ACCOUNT_DELETION_CANCEL_AT_OTP: `${appV2EventPrefix} Cancel Request Account Deletion At OTP`,
  REQUEST_ACCOUNT_DELETION_CANCEL_AT_CONFIRM: `${appV2EventPrefix} Cancel Request Account Deletion At Confirmation`,

  // Agreement
  SIGN_AGREEMENT_VIEWED: `${appV2EventPrefix} Viewed sign agreement page`,

  // Sign new agreement
  SIGN_NEW_AGREEMENT_SEND_OTP: `${appV2EventPrefix} Request OTP for sign new agreement`,
  SIGN_NEW_AGREEMENT_STARTED: `${appV2EventPrefix} Sign new agreement`,
  SIGN_NEW_AGREEMENT_MODAL_DISMISS: `${appV2EventPrefix} Dismissed sign new agreement modal`,
  SIGN_NEW_AGREEMENT_MODAL_CONTINUE: `${appV2EventPrefix} Clicked continue on sign new agreement modal`,

  // Attendance Score
  ATTENDANCE_SCORE_PAGE_OPENED_FROM_FEEDBACK_MODAL: `${appV2EventPrefix} Attendance Score Page Opened From Feedback Modal`,

  // Debit Card Expiration Banner
  TAPPED_DEBIT_CARD_ABOUT_TO_EXPIRE_BANNER: `${appV2EventPrefix} Tapped Debit Card About To Expire Banner`,
  TAPPED_DEBIT_CARD_EXPIRED_BANNER: `${appV2EventPrefix} Tapped Debit Card Expired Banner`,
};

export const APP_V2_APP_EVENTS = {
  // Onboarding
  STRIPE_ONBOARDING_ERROR: `${appV2EventPrefix} Stripe Onboarding Error`,
  AGREEMENT_SIGNING_ERROR: `${appV2EventPrefix} Agreement Signing Error`,
  GET_WORKER_ONBOARDING_FAILURE: `${appV2EventPrefix} Failed to get worker onboarding`,
  PATCH_WORKER_ONBOARDING_FAILURE: `${appV2EventPrefix} Failed to update worker onboarding`,

  // App
  APPLICATION_CRASHED: `${appV2EventPrefix} Application Crashed`,
  FIREBASE_OTP_SIGN_IN_ERROR: `${appV2EventPrefix} Firebase signInWithCustomToken error`,
  AGENT_PROFILE_LOGGED_IN_ERROR: `${appV2EventPrefix} API request to /agentProfile/loggedIn failed`,
  APP_INFO_ERROR: `${appV2EventPrefix} Failed to load Capacitor App info`,
  DEVICE_INFO_ERROR: `${appV2EventPrefix} Failed to load Capacitor Device info`,
  API_SCHEMA_VALIDATION_ERROR: `${appV2EventPrefix} API Zod Schema validation error`,
  FEATURE_FLAG_SCHEMA_VALIDATION_ERROR: `${appV2EventPrefix} Feature Flag Zod schema validation error`,
  INVALID_SET_OF_COORDINATES_ERROR: `${appV2EventPrefix} Invalid set of coordinates error`,

  // Auth
  REQUEST_OTP_FAILURE: `${appV2EventPrefix} Failed to request OTP`,
  VALIDATE_OTP_FAILURE: `${appV2EventPrefix} Failed to validate OTP`,
  AUTH_ON_SUBMIT_FAILURE: `${appV2EventPrefix} Failed to initiate authentication during onSubmit`,
  REQUEST_LOGIN_LINK_FAILURE: `${appV2EventPrefix} Failed to request login link`,

  // Agent
  GET_AGENT_PROFILE_FAILURE: `${appV2EventPrefix} Failed to load agent profile`,
  UPDATE_AGENT_PROFILE_FAILURE: `${appV2EventPrefix} Failed to update agent profile`,

  SAVE_AGENT_PREFERENCES_SUCCESS: `${appV2EventPrefix} Saved agent preferences`,
  SAVE_AGENT_PREFERENCES_FAILURE: `${appV2EventPrefix} Failed to load agent preferences`,
  GET_AGENT_PROFILE_PICTURE_FAILURE: `${appV2EventPrefix} Failed to get agent profile picture`,

  GET_AGENT_BY_PHONE_FAILURE: `${appV2EventPrefix} Failed to load agent by phone`,
  POST_AGENT_LOGGED_IN_FAILURE: `${appV2EventPrefix} Failed to save agent logged in`,

  GET_AGENT_SENT_HOME_CANCELLATION_PAY_PARAMS_FAILURE: `${appV2EventPrefix} Failed to load agent sent home cancellation pay params`,
  GET_AGENT_FRIENDS_FAILURE: `${appV2EventPrefix} Failed to load agent friends`,
  GET_AGENT_FRIEND_REQUESTS_FAILURE: `${appV2EventPrefix} Failed to load agent friend requests`,
  GET_RECENT_COLLEAGUES_FAILURE: `${appV2EventPrefix} Failed to load agent friend requests`,
  GET_SENT_HOME_REQUESTS_FAILURE: `${appV2EventPrefix} Failed to load sent home requests`,
  GET_OPEN_NEGOTIATIONS_COUNT_FAILURE: `${appV2EventPrefix} Failed to load open negotiations count`,
  GET_RATE_NEGOTIATIONS_LIST_FAILURE: `${appV2EventPrefix} Failed to load rate negotiations list`,
  GET_NEGOTIATION_SHIFT_DETAILS_FAILURE: `${appV2EventPrefix} Failed to load negotiation shift details`,
  CREATE_NEGOTIATION_FAILURE: `${appV2EventPrefix} Failed to create negotiation`,

  // Streaks
  GET_AGENT_STREAKS_FAILURE: `${appV2EventPrefix} Failed to load agent streaks`,
  GET_AGENT_STREAKS_PROGRESS_FAILURE: `${appV2EventPrefix} Failed to load agent streaks progress`,
  GET_AGENT_STREAKS_BONUS_FAILURE: `${appV2EventPrefix} Failed to load agent streaks bonus`,
  GET_FACILITY_STREAKS_SETTINGS_FAILURE: `${appV2EventPrefix} Failed to load facility streaks settings`,

  // Facility
  GET_FACILITY_PROFILE_FAILURE: `${appV2EventPrefix} Failed to load facility profile`,
  GET_FACILITY_PHOTOS_FAILURE: `${appV2EventPrefix} Failed to load facility photos`,
  GET_FACILITY_RATINGS_FAILURE: `${appV2EventPrefix} Failed to load facility ratings`,
  GET_FACILITY_STREET_IMAGE_URL_FAILURE: `${appV2EventPrefix} Failed to load facility Google Maps street image URL`,
  GET_FACILITY_VERIFICATION_PREFERENCES_FAILURE: `${appV2EventPrefix} Failed to load facility verification preferences`,
  GET_SHIFT_SLOTS_FAILURE: `${appV2EventPrefix} Failed to get shift slots`,
  GET_FACILITY_EXCLUSION_FAILURE: `${appV2EventPrefix} Failed to load facility exclusion`,

  // WorkplaceQuiz
  GET_WORKPLACE_QUIZ_FAILURE: `${appV2EventPrefix} Failed to load workplace quiz`,
  GET_WORKPLACE_QUIZ_RESULT_LOGS_FAILURE: `${appV2EventPrefix} Failed to load workplace quiz result logs`,
  SUBMIT_WORKPLACE_QUIZ_FAILURE: `${appV2EventPrefix} Failed to submit workplace quiz`,

  // Facility specs
  // TODO(clipboardhealth.atlassian.net/browse/TX-761): Remove const usage after the complete rollout and flag removal
  GET_FACILITY_SOLVE_UNPAID_WORKED_BREAKS_STAGE: `${appV2EventPrefix} Failed to load facility SolveWorkedUnpaidBreaks stage`,

  // Facility notes
  GET_MANDATORY_BREAK_POLICY_ACKNOWLEDGEMENT_FAILURE: `${appV2EventPrefix} Failed to load mandatory break policy acknowledgement`,
  POST_MANDATORY_BREAK_POLICY_ACKNOWLEDGEMENT_FAILURE: `${appV2EventPrefix} Failed to post mandatory break policy acknowledgement`,
  GET_FACILITY_NOTES_FAILURE: `${appV2EventPrefix} Failed to load facility notes`,

  // Documents
  GET_DISTRIBUTION_URL_FAILURE: `${appV2EventPrefix} Failed to get document url`,
  CONSTRUCT_PDF_FROM_IMAGES_FAILURE: `${appV2EventPrefix} Failed to get create pdf from images`,
  PRESIGNED_URL_UPLOAD_FAILURE: `${appV2EventPrefix} Failed to get presigned url`,
  DOCUMENT_UPLOAD_CAMERA_TAKE_PHOTO_FAILURE: `${appV2EventPrefix} Failed to take photo with camera`,
  DOCUMENT_UPLOAD_CAMERA_TAKE_PHOTO_PERMISSION_DENIED: `${appV2EventPrefix} Permission denied for camera in document upload`,
  DOCUMENT_UPLOAD_MOBILE_FILE_PICKER_FAILURE: `${appV2EventPrefix} Failed to choose file on mobile`,
  DOCUMENT_UPLOAD_BROWSER_FILE_PICKER_FAILURE: `${appV2EventPrefix} Failed to choose file on browser`,
  GET_REJECTED_DOCUMENT_NOTIFICATION_FAILURE: `${appV2EventPrefix} Failed to get rejected document notification`,
  GET_DOCUMENT_ID_FAILURE: `${appV2EventPrefix} Failed to get document id`,
  GET_REQUIREMENT_EXPIRY_FAILURE: `${appV2EventPrefix} Failed to get requirement expiry`,
  CREATE_STRIPE_VERIFICATION_SESSION_FAILURE: `${appV2EventPrefix} Failed to create stripe identity verification session`,
  STRIPE_IDENTITY_VERIFICATION_SESSION_FAILURE: `${appV2EventPrefix} Error during stripe identity session`,
  STRIPE_IDENTITY_FLOW_BEGUN: `${appV2EventPrefix} Stripe Identity Flow Begun`,
  STRIPE_IDENTITY_FLOW_COMPLETED: `${appV2EventPrefix} Stripe Identity Flow Completed`,
  DELETE_DOCUMENT_FAILURE: `${appV2EventPrefix} Error while trying to delete document`,
  CREATE_DOCUMENT_FAILURE: `${appV2EventPrefix} Error while trying to create document`,
  DELETE_DOCUMENT_AFTER_STRIPE_IDENTITY_FAILURE: `${appV2EventPrefix} Error while trying to delete document after stripe failure`,
  STRIPE_IDENTITY_SDK_SESSION_EVENT: `${appV2EventPrefix} Stripe Identity Native SDK Event`,
  STRIPE_IDENTITY_SDK_SESSION_RESULT: `${appV2EventPrefix} Stripe Identity Native SDK Result`,
  MISSING_DOCUMENTS_ALERT_NOTIFY_SUPPORT_FAILURE: `${appV2EventPrefix} Failed to notify support from missing documents alerts`,

  // Open Shifts
  GET_OPEN_SHIFT_COUNT_FAILURE: `${appV2EventPrefix} Failed to load open shift count`,
  GET_OPEN_SHIFT_BY_DATE_FAILURE: `${appV2EventPrefix} Failed to load open shift by date`,
  GET_USER_TIMEZONE_FAILURE: `${appV2EventPrefix} Failed to load user timezone`,
  GET_AGENT_SHIFTS_FAILURE: `${appV2EventPrefix} Failed to load agent shifts`,
  WORKER_CALENDAR_STATS: `${appV2EventPrefix} Worker Calendar Stats`,
  GET_MAP_VIEW_FACILITY_OPEN_SHIFT_COUNT_FAILURE: `${appV2EventPrefix} Failed to load map view facility open shift count`,
  GET_FACILITY_VIEW_OPEN_SHIFTS_FAILURE: `${appV2EventPrefix} Failed to load facility view open shifts`,

  // Shift Actions
  POST_CLAIM_SHIFT_FAILURE: `${appV2EventPrefix} Failed to claim shift`,
  POST_MARK_INTEREST_SHIFT_FAILURE: `${appV2EventPrefix} Failed to mark interest for shift`,
  POST_ACCEPT_NEGOTIATION_FAILURE: `${appV2EventPrefix} Failed to accept negotiation`,

  // Shift
  GET_SHIFT_STATE_FAILURE: `${appV2EventPrefix} Failed to load shift state`,
  GET_APP_CONFIGURATION_FAILURE: `${appV2EventPrefix} Failed to load shift configuration`,
  UPLOAD_SHIFT_TIMECARD_TO_S3_FAILURE: `${appV2EventPrefix} Failed to upload timecard to AWS S3`,
  UPDATE_SHIFT_TIMECARD_FAILURE: `${appV2EventPrefix} Failed to update shift timecard`,
  CANCEL_SHIFT_FAILURE: `${appV2EventPrefix} Failed to cancel shift`,
  GET_SHIFT_FAILURE: `${appV2EventPrefix} Failed to get shift`,
  GET_UPCOMING_SHIFTS_FAILURE: `${appV2EventPrefix} Failed to get upcoming shifts for agent`,

  // MyShifts
  GET_MAX_EARNINGS_FAILURE: `${appV2EventPrefix} Failed to load max earnings`,
  GET_NEXT_TWO_DAYS_SHIFTS_FAILURE: `${appV2EventPrefix} Failed to load next two days shifts`,
  GET_FUTURE_SHIFTS_FAILURE: `${appV2EventPrefix} Failed to load future shifts`,

  // Unverified shifts
  GET_UNVERIFIED_SHIFTS_FAILURE: `${appV2EventPrefix} Failed to load unverified shifts`,
  TIMESHEET_PHOTO_UPLOADED: `${appV2EventPrefix} Timesheet Photo Uploaded`,
  CANCEL_TIMESHEET_PHOTO_FORGOTTEN: `${appV2EventPrefix} Timesheet Photo Forgotten`,
  CANCEL_TIMECARD_SENT_ANOTHER_WAY: `${appV2EventPrefix} Timecard Sent Another Way`,
  CANCEL_SHIFT_NOT_ATTENDED: `${appV2EventPrefix} Shift Not Attended`,
  SET_TIMESHEET_AVAILABILITY_FAILURE: `${appV2EventPrefix} Failed to set timesheet availability`,

  // Shift invites
  GET_PENDING_SHIFT_INVITES: `${appV2EventPrefix} Failed to load pending shift invites`,
  ACCEPT_OR_DECLINE_SHIFT_INVITE: `${appV2EventPrefix} Failed to accept/decline shift invite`,
  ACCEPT_OVERWORKING_SHIFT_INVITE: `${appV2EventPrefix} Accepting overworking shift invite`,
  SHIFT_INVITE_SCHEMA_VALIDATION_ERROR: `${appV2EventPrefix} API Zod Schema validation error`,
  SHIFT_INVITE_BANNER_CLICK: `${appV2EventPrefix} Shift Invite banner click`,
  SHIFT_INVITE_PENDING_COUNT_CLICK: `${appV2EventPrefix} Shift Invite pending count click`,
  SHIFT_INVITE_ERROR: `${appV2EventPrefix} Shift Invite error`,
  SHIFT_INVITE_CONFLICTING_SHIFT_FOUND: `${appV2EventPrefix} Shift Invite conflicting shift found`,

  // GCP
  POST_SIGN_URL_FAILURE: `${appV2EventPrefix} Failed to sign url for GCP`,

  // Google Places Autocomplete
  GET_ADDRESS_INCOMPLETE_DATA_FAILURE: `${appV2EventPrefix} Failed to get complete address from Places Autocomplete`,

  // Reviews
  GET_REVIEW_QUESTIONNAIRE_FAILURE: `${appV2EventPrefix} Failed to load review questions`,
  POST_REVIEW_FAILURE: `${appV2EventPrefix} Failed to post the review`,
  POST_COMMENT_FAILURE: `${appV2EventPrefix} Failed to post the comment`,
  PATCH_COMMENT_FAILURE: `${appV2EventPrefix} Failed to patch the comment`,
  POST_RATING_FAILURE: `${appV2EventPrefix} Failed to post the rating`,
  GET_RATING_AGGREGATES_FAILURE: `${appV2EventPrefix} Failed to get rating aggregates`,
  GET_MULTIPLE_CHOICE_AGGREGATES_FAILURE: `${appV2EventPrefix} Failed to get multiple choice aggregates`,
  GET_THREAD_AGGREGATES_FAILURE: `${appV2EventPrefix} Failed to get thread aggregates`,
  GET_REVIEW_COMMENTS_FAILURE: `${appV2EventPrefix} Failed to get review comments`,
  POST_COMMENT_REACTION: `${appV2EventPrefix} Failed to post comment reaction`,
  DELETE_COMMENT_REACTION: `${appV2EventPrefix} Failed to delete comment reaction`,
  GET_WORKPLACE_WORKERS_SHIFTS_FAILURE: `${appV2EventPrefix} Failed to get workplace worker shifts`,
  REVIEWS_TAG_SCREEN_ERROR: `${appV2EventPrefix} Failed to tag reviews screen`,
  GET_REVIEW_COMMENT_FAILURE: `${appV2EventPrefix} Failed to get review comments`,

  // Worker Availability
  GET_AVAILABILITIES_API_FAILURE: `${appV2EventPrefix} Failed to load availabilities`,
  PUT_AVAILABILITIES_API_FAILURE: `${appV2EventPrefix} Failed to save availabilities`,

  // Requirement Status
  GET_REQUIREMENT_STATUS_FAILURE: `${appV2EventPrefix} Failed to load requirement status`,

  // Checkr Application
  GET_CHECKR_APPLICATION_STATUS_FAILURE: `${appV2EventPrefix} Failed to load checkr application status`,
  RESEND_CHECKR_INVITATION_FAILURE: `${appV2EventPrefix} Failed to resend checkr invitation`,
  SEND_ONBOARDING_CHECKR_INVITATION_FAILURE: `${appV2EventPrefix} Failed to send onboarding checkr invite`,

  // Documents
  GET_FILTERED_DOCUMENTS_FAILURE: `${appV2EventPrefix} Failed to load filtered documents`,
  GET_MISSING_REQUIREMENTS_BY_DATE_FAILURE: `${appV2EventPrefix} Failed to load missing requirements by date`,

  // Professional References
  GET_PROFESSIONAL_REFERENCES_FAILURE: `${appV2EventPrefix} Failed to load professional references`,

  // Workplace Referrals
  GET_WORKPLACE_REFERRAL_STATS_FAILURE: `${appV2EventPrefix} Failed to load workplace referral stats`,
  GET_WORKPLACE_REFERRALS_FAILURE: `${appV2EventPrefix} Failed to load workplace referrals`,

  // Worker to Worker Referrals
  GET_WORKER_REFERRAL_BONUSES_FAILURE: `${appV2EventPrefix} Failed to load worker to worker referral bonuses`,
  GET_WORKER_REFERRAL_PROFILE_FAILURE: `${appV2EventPrefix} Failed to load worker referral profile`,
  SHARE_REFERRAL_CODE_ERROR: `${appV2EventPrefix} Failed to complete sharing worker to worker referral code`,

  // WorkerReferrals
  POST_SHARE_YOUR_PROFILE_FAILURE: `${appV2EventPrefix} Failed to share your profile`,
  GET_WORKER_REFERRAL_CODE_FAILURE: `${appV2EventPrefix} Failed to get worker referral code`,
  POST_WORKER_REFERRAL_CODE_FAILURE: `${appV2EventPrefix} Failed to create worker referral code`,

  GET_AGENT_STATS_FAILURE: `${appV2EventPrefix} Failed to get agent stats`,

  // Work With Friends
  GET_WORKER_FRIEND_LINK_FAILURE: `${appV2EventPrefix} Failed to load worker friend link`,

  // Urgent Shifts
  GET_URGENT_SHIFTS_FAILURE: `${appV2EventPrefix} Failed to load unverified shifts`,
  GET_CHECK_ATTENDANCE_V1_FAILURE: `${appV2EventPrefix} Failed to check attendance v1`,
  POST_CONFIRM_ATTENDANCE_FAILURE: `${appV2EventPrefix} Failed to confirm attendance`,
  UPDATE_YOUR_ETA_FAILURE: `${appV2EventPrefix} Failed to update the ETA for shift`,

  // Referrals
  GET_REFERRALS_ELIGIBILITY_FAILURE: `${appV2EventPrefix} Failed to get referrals eligibility`,

  // Location
  GET_DISTANCE_BETWEEN_TWO_GEOLOCATIONS_FAILURE: `${appV2EventPrefix} Failed to get distance between two geolocations`,
  LOCATION_VERIFICATION_CHECK_FAILURE: `${appV2EventPrefix} Location Verification Check failed`,
  GET_DEVICE_GEOLOCATION_IF_ALLOWED_FAILURE: `${appV2EventPrefix} Get Device GeoLocation Failed`,
  IS_DEVICE_LOCATION_PERMISSION_GRANTED_FAILURE: `${appV2EventPrefix} Get Is Device Location Permission Granted Failed`,
  SKIP_ALWAYS_ALLOW_DEVICE_SETTING_CHECK: `${appV2EventPrefix} Get Is Device Location Permission Granted Failed`,
  TRACKING_MODE_CHANGE_FAILURE: `${appV2EventPrefix} Tracking Mode Change Failed`,
  HANDLE_COMMUTE_TOPIC_SUBSCRIPTION_FAILURE: `${appV2EventPrefix} Handle Commute Topic Subscription Failed`,
  FAILED_TO_ADD_IOS_SILENT_NOTIFICATION_LISTENER: `${appV2EventPrefix} Failed to add iOS silent notification listener`,
  HANDLE_SILENT_PUSH_NOTIFICATION_ERROR: `${appV2EventPrefix} Handle Silent Push Notification Failed`,
  TRIP_TRACKING_SILENT_NOTIFICATION_RECEIVED: `${appV2EventPrefix} Trip Tracking Silent Notification Received`,
  TRIP_TRACKING_SILENT_NOTIFICATION_DISCARDED: `${appV2EventPrefix} Trip Tracking Silent Notification Discarded`,
  TRIP_TRACKING_STOPPED: `${appV2EventPrefix} Trip Tracking Stopped`,
  TRIP_TRACKING_STARTED: `${appV2EventPrefix} Trip Tracking Started`,
  TRIP_TRACKING_ERROR: `${appV2EventPrefix} Trip Tracking Error`,
  TRIP_TRACKING_NOT_CALLED: `${appV2EventPrefix} Trip Tracking Not Called`,
  WORKER_LEFT_EARLY: `${appV2EventPrefix} Worker Left Early`,
  WORKER_DEPARTURE_UPDATE_FAILURE: `${appV2EventPrefix} Worker Departure Update Failure`,
  WORKER_UPDATED_LOCATION_SETTINGS: `${appV2EventPrefix} Worker Updated Location Settings`,
  LOCATION_ACCURACY_ERROR: `${appV2EventPrefix} Error while getting the location accuracy status`,
  HYPER_TRACK_TRIAL_ENABLED: `${appV2EventPrefix} HyperTrack Trial Enabled`,
  HYPER_TRACK_INIT_SUCCESS: `${appV2EventPrefix} HyperTrack Init Success`,
  HYPER_TRACK_INIT_FAILURE: `${appV2EventPrefix} HyperTrack Init Failure`,
  HYPER_TRACK_RESET_SUCCESS: `${appV2EventPrefix} HyperTrack Reset Success`,
  HYPER_TRACK_RESET_FAILURE: `${appV2EventPrefix} HyperTrack Reset Failure`,
  HYPER_TRACK_TRIP_TRACKING_STARTED: `${appV2EventPrefix} HyperTrack Trip Tracking Started`,
  HYPER_TRACK_TRIP_TRACKING_FAILED: `${appV2EventPrefix} HyperTrack Trip Tracking Failed`,
  HYPER_TRACK_GEOFENCE_CHECK: `${appV2EventPrefix} HyperTrack Geofence Check`,
  HYPER_TRACK_GEOFENCE_CHECK_SUCCEEDED: `${appV2EventPrefix} HyperTrack Geofence Check Succeeded`,
  HYPER_TRACK_GEOFENCE_CHECK_FAILED: `${appV2EventPrefix} HyperTrack Geofence Check Failed`,

  // Chat
  GET_CHAT_CHANNELS_FAILURE: `${appV2EventPrefix} Failed to get chat channels`,

  // Shift bookability
  GET_BOOKABILITY_STATUS_FAILURE: `${appV2EventPrefix} Failed to load bookability status`,
  SHIFT_BLOCKED_CRITERIA_FAILURE: `${appV2EventPrefix} Shift Bookability Unmet Criteria Failure`,
  GET_PAGINATED_BOOKABILITY_STATUS_FAILURE: `${appV2EventPrefix} Failed to load paginated bookability status`,

  // Mandatory breaks
  GET_EXTRA_WORKED_TIME_REQUESTS_FAILURE: `${appV2EventPrefix} Failed to get extra worked time request`,

  // Zendesk
  ZENDESK_LOGIN_ERROR: `${appV2EventPrefix} Zendesk login error`,

  // Shift Invite
  SHIFT_INVITE_IN_CHAT: `${appV2EventPrefix} Shift Invite In Chat`,

  // Licenses
  GET_LICENSES_FAILURE: `${appV2EventPrefix} Failed to load licenses`,
  GET_LICENSE_DETAILS_FAILURE: `${appV2EventPrefix} Failed to load license details`,

  // Shift Blocks
  GET_SHIFT_BLOCK_FAILURE: `${appV2EventPrefix} Failed to load shift block`,
  UPDATE_SHIFT_BLOCK_FAILURE: `${appV2EventPrefix} Failed to update shift block`,
  GET_WORKER_PREFERRED_STATUS_FAILURE: `${appV2EventPrefix} Failed to get worker's preferred status`,
  REQUEST_TO_BOOK_SHIFT_BLOCK_FAILURE: `${appV2EventPrefix} Failed to request to book shift block`,
  POST_CREATE_CHAT_CHANNEL_FAILURE: `${appV2EventPrefix} Failed to create chat channel`,

  // Qualifications
  GET_QUALIFICATIONS_FAILURE: `${appV2EventPrefix} Failed to get qualifications`,

  // Attendance score
  GET_ATTENDANCE_SCORE_HISTORY: `${appV2EventPrefix} Failed to load agent recent attendance score history`,
  GET_ATTENDANCE_SCORE_PROFILE: `${appV2EventPrefix} Failed to load agent attendance score profile`,

  // Account deletion
  REQUEST_ACCOUNT_DELETION_FAILURE: `${appV2EventPrefix} Failed to request account deletion`,
  CREATE_ACCOUNT_REACTIVATION_REQUEST_FAILURE: `${appV2EventPrefix} Failed to create account reactivation request`,
  EDIT_ACCOUNT_REACTIVATION_REQUEST_FAILURE: `${appV2EventPrefix} Failed to edit account reactivation request`,

  // Pdf viewer
  PDF_LIBRARY_SETUP_FAILURE: `${appV2EventPrefix} Failed to setup pdf.js library`,
  PDF_LOAD_FAILURE: `${appV2EventPrefix} Failed to load pdf`,
  PDF_LOAD_SUCCESS: `${appV2EventPrefix} Successfully loaded pdf`,
  PDF_DOWNLOAD_FAILURE: `${appV2EventPrefix} Failed to download pdf`,
  PDF_DOWNLOADED: `${appV2EventPrefix} Pdf downloaded`,
  PDF_PLATFORM_CHECK_FAILURE: `${appV2EventPrefix} Failed to check pdf platform`,
  PDF_PLATFORM_UNSUPPORTED_ONBOARDING: `${appV2EventPrefix} Pdf platform is unsupported at onboarding`,

  // Agreements
  GET_SIGNED_AGREEMENTS_FAILURE: `${appV2EventPrefix} Failed to get signed agreements`,
  GET_AGREEMENTS_FAILURE: `${appV2EventPrefix} Failed to get agreements`,

  // Sign new agreement
  SIGN_NEW_AGREEMENT_FAILURE: `${appV2EventPrefix} Failed to sign new agreement`,
  SIGN_NEW_AGREEMENT_SUCCESS: `${appV2EventPrefix} Successfully signed new agreement`,
  SIGN_NEW_AGREEMENT_MODAL_VIEW: `${appV2EventPrefix} Viewed sign new agreement modal`,

  // Proof of Earnings
  POST_SEND_PROOF_OF_EARNINGS_EMAIL_FAILURE: `${appV2EventPrefix} Failed to send proof of earnings email`,

  // Debit Card Expiration Status
  GET_EXTERNAL_PAYMENT_ACCOUNTS_FAILURE: `${appV2EventPrefix} Failed to get external payment accounts`,
};
